import { Injectable, EventEmitter } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
    providedIn: 'root'
})
export class ArmazenamentoService {

    constructor(
        private router: Router
    ) { }

    obterItem(nome) {
        if (typeof window !== 'undefined'){
            if (this.obterModalidadeLogin() === 'session') {
                return sessionStorage.getItem(nome)    
            } else {
                return localStorage.getItem(nome)
            }
        }else{
            return null
        }
    }

    definirItem(nome, valor) {
        if (typeof window !== 'undefined'){
            if (this.obterModalidadeLogin() === 'session') {
                sessionStorage.setItem(nome, valor)
            } else {
                localStorage.setItem(nome, valor)
            }
        }
    }

    obterModalidadeLogin() {
        let modalidadeLogin
        
        modalidadeLogin = localStorage.getItem('modalidadeLogin')
        /**
         * Se foi modificado o valor da modalidade do login, por segurança, limpamos o
         * "local" e o "session" storage e forçamos o usuário a fazer login novamente.
         */
        if (modalidadeLogin !== 'session' && modalidadeLogin !== 'local') {
            /*
            *   Alterado .clear() para .remover(), afim de limpar apenas dados de segurança,
            *   e manter dados de cache
            * 
            * localStorage.clear()
            * sessionStorage.clear()
            */
            if (typeof window !== 'undefined') {
                localStorage.removeItem('modalidadeLogin')
                localStorage.removeItem('LRLogU')
                sessionStorage.removeItem('modalidadeLogin')
                sessionStorage.removeItem('LRLogU')
                localStorage.setItem('modalidadeLogin', 'session')
            }
            
            this.router.navigate(['/login'])
        }

        return modalidadeLogin
    }
}
